@import 'src/styles/variables';

.root {
  position: relative;

  display: grid;
  grid-template-areas:
    'header'
    'arrow'
    'content'
    'footer';
  grid-template-rows:
    auto
    auto
    1fr
    auto;
  align-items: self-start;
  width: 100%;
  min-width: 100%;
  height: auto;
  min-height: 100vh;
  padding-top: var(--default-header-height);

  overflow: hidden;

  @media (max-width: $lg) {
    padding-top: var(--mobile-header-height);
  }
}

.hasHeaderBanner {
  padding-top: 110px;

  @media (max-width: $lg) {
    padding-top: 94px;
  }
}

.skipLink {
  &:focus-within {
    z-index: 10;

    display: block;
    width: unset;
    height: unset;
    padding: 1em;
    overflow: unset;

    white-space: unset;

    background-color: var(--text-secondary);

    clip: unset;
    clip-path: unset;
  }
}

.arrowBreadCrumbs {
  position: relative;

  grid-area: arrow;

  max-width: $fix-container-width;
  padding-inline: var(--page-padding-inline);
  padding-block-start: 24px;
  padding-block-end: var(--page-padding-block-end);

  @media (min-width: ($lg + 1px)) and (max-width: $xl) {
    padding-inline: var(--page-padding-inline);
    --page-padding-inline: 0;
    --page-padding-block-end: 24px;
  }

  @media (max-width: $lg) {
    --page-padding-inline: 0;
    --page-padding-block-end: 16px;
  }

  @media (min-width: ($md + 1px)) and (max-width: $lg) {
    --page-padding-inline: 0;
    --page-padding-block-end: 16px;
  }

  @media (min-width: ($xl + 1px)) {
    --page-padding-inline: 0;
    --page-padding-block-end: 24px;
  }

  @media (min-width: #{$fix-container-width}) {
    --page-padding-inline: 0;

    margin-inline: auto;
  }
}

.contentContainer {
  position: relative;

  grid-area: content;

  width: 100vw;
  max-width: $fix-container-width;
  padding-inline: var(--page-padding-inline);
  padding-block-start: var(--page-padding-block-start);
  padding-block-end: 64px;

  @media (max-width: $lg) {
    --page-padding-inline: 16px;
    --page-padding-block-start: 32px;
  }

  @media (min-width: ($lg + 1px)) and (max-width: $xl) {
    --page-padding-inline: 24px;
    --page-padding-block-start: 48px;
  }

  @media (min-width: ($md + 1px)) and (max-width: $lg) {
    --page-padding-inline: 24px;
    --page-padding-block-start: 32px;
  }

  @media (min-width: ($xl + 1px)) {
    --page-padding-inline: 32px;
    --page-padding-block-start: 48px;
  }

  @media (min-width: #{$fix-container-width}) {
    --page-padding-inline: 0;

    margin-inline: auto;
  }
}

.contentContainerAlert {
  max-width: $fix-container-width;
  padding-inline: var(--page-padding-inline);
  padding-block: var(--page-padding-block);

  @media (max-width: $lg) {
    --page-padding-inline: 16px;
    --page-padding-block: 0;
  }

  @media (min-width: ($lg + 1px)) and (max-width: $xl) {
    --page-padding-inline: 24px;
    --page-padding-block: 0;
  }

  @media (min-width: ($md + 1px)) and (max-width: $lg) {
    --page-padding-inline: 24px;
    --page-padding-block: 0;
  }

  @media (min-width: ($lg + 1px)) {
    --page-padding-inline: 32px;
    --page-padding-block: 0;
  }

  @media (min-width: #{$fix-container-width}) {
    --page-padding-inline: 0;

    margin-inline: auto;
  }
}

.hasBreadCrumbs {
  padding-inline: var(--page-padding-inline);
  padding-block-start: 0;
}

.fullHeight {
  height: 100%;
}

.fullWidth {
  max-width: inherit;
}

.shortWidth {
  max-width: 512px;

  margin-inline: auto;
}

.removeBlockEndPadding {
  padding-block-end: 0;
}
