@import 'src/styles/variables';

.block {
  position: absolute;
  bottom: -336px;
  left: 0;

  width: 100vw;
  height: 536px;
  padding-top: 188px;

  text-align: center;

  background-color: var(--palettes-blue-20);

  transition: bottom 0.8s ease-out;
}

.title {
  width: 400px;
  margin: 30px auto 56px;

  @media (max-width: $xl) {
    width: auto;
    margin: 56px auto;
  }
}

.isInView {
  bottom: 0;
}
